import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <footer className="bg-dark text-white py-8 mt-12">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-6">
        {/* Left Section - Company Info */}
        <div className="text-center md:text-left">
          <h2 className="text-2xl font-bold">Tabsamo Solutions</h2>
          <p className="text-gray-400 mt-2">Empowering businesses with tech solutions.</p>
        </div>

        {/* Middle Section - Quick Links */}
        <div className="mt-6 md:mt-0">
          <h3 className="text-xl font-semibold">Quick Links</h3>
          <ul className="mt-2 space-y-2 text-gray-400">
            <li><Link to="/" className="hover:text-primary">Home</Link></li>
            <li><Link to="/about" className="hover:text-primary">About</Link></li>
            <li><Link to="/services" className="hover:text-primary">Services</Link></li>
            <li><Link to="/projects" className="hover:text-primary">Projects</Link></li>
            <li><Link to="/contact" className="hover:text-primary">Contact</Link></li>
          </ul>
        </div>

        {/* Right Section - Social Media */}
        <div className="mt-6 md:mt-0">
          <h3 className="text-xl font-semibold">Follow Us</h3>
          <div className="flex mt-3 space-x-4">
            <a href="https://www.facebook.com/tabsamo?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-primary text-2xl">
              <FaFacebook />
            </a>
            <a href="https://twitter.com/tabsamo" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-primary text-2xl">
              <FaTwitter />
            </a>
            <a href="https://www.linkedin.com/company/tabsamo" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-primary text-2xl">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>
      
      {/* Copyright Section Centered */}
      <div className="text-center text-gray-500 mt-4 mb-0">
        &copy; {new Date().getFullYear()} Tabsamo. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
