import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  // Function to check if link is active
  const isActive = (path: string) =>
    location.pathname === path ? "text-primary" : "hover:text-secondary";

  // Sticky Navbar Effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full bg-dark text-white p-4 flex justify-between items-center transition-all duration-300 z-50 ${
        isSticky ? "shadow-md bg-opacity-90" : ""
      }`}
    >
      <Link to="/" className="text-2xl font-bold text-primary">
        Tabsamo
      </Link>

      {/* Desktop Menu */}
      <ul className="hidden md:flex gap-6">
        <li>
          <Link to="/" className={isActive("/")}>Home</Link>
        </li>
        <li>
          <Link to="/projects" className={isActive("/projects")}>
            Projects
          </Link>
        </li>
        <li>
          <Link to="/services" className={isActive("/services")}>
            Services
          </Link>
        </li>
        <li>
          <Link to="/about" className={isActive("/about")}>
            About
          </Link>
        </li>
        <li>
          <Link to="/contact" className={isActive("/contact")}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Mobile Menu Button */}
      <button
        className="md:hidden text-2xl z-50 relative"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-900 bg-opacity-90 backdrop-blur-lg p-6 transform shadow-lg md:hidden z-50 transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <ul className="flex flex-col gap-6 mt-10">
          <li>
            <Link to="/" className={isActive("/")} onClick={() => setIsOpen(false)}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className={isActive("/projects")}
              onClick={() => setIsOpen(false)}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              className={isActive("/services")}
              onClick={() => setIsOpen(false)}
            >
              Services
            </Link>
          </li>
          <li>
            <Link to="/about" className={isActive("/about")} onClick={() => setIsOpen(false)}>
              About
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={isActive("/contact")}
              onClick={() => setIsOpen(false)}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
