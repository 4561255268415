import React from "react";
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import image5 from "../assets/image5.png";
const services = [
  { title: "Software Development", image: image1, desc: "We provide cutting-edge software solutions tailored to your needs." },
  { title: "Research and Development", image: image2, desc: "Innovative R&D solutions to drive technological advancements." },
  { title: "Digital Marketing", image: image3, desc: "Boost your online presence with strategic digital marketing." },
  { title: "Business Solutions", image: image4, desc: "Smart solutions to enhance and streamline your business." },
  { title: "Skill Buildings", image: image5, desc: "Empowering individuals with the skills for the future." },
];


const Services = () => {
  return (
    <section className="bg-gradient-to-r from-black via-gray-900 to-black text-white py-16 px-6 mt-10">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-12 text-blue-500">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-gray-800 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300"
            >
              <img src={service.image} alt={service.title} className="w-full h-48 object-cover rounded-lg mb-4" />
              <h3 className="text-2xl font-semibold mb-2">{service.title}</h3>
              <p className="text-gray-400">{service.desc}</p>
              
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
