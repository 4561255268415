import Services from "./Services"; // Import Services section
import Projects from "./Projects"; // Import Projects section
import heroImage from "../assets/hero.png"; // Import hero image
import About from "./About"; // Import About section
import Contact from "./Contact";

const Home = () => {
  return (
    <section className="bg-gradient-to-r from-black via-gray-900 to-black text-white min-h-screen flex flex-col items-center justify-center">
      <div className="container mx-auto flex flex-col md:flex-row items-center px-6 mt-10">
      <div className="md:w-1/2 text-center md:text-left mt-10">
      <h1 className="text-4xl md:text-6xl font-bold leading-tight animate-fade-slide-up">
        Next Level <span className="text-primary">Machine</span> Intelligence
      </h1>
      <p className="mt-4 text-lg text-gray-300 animate-fade-slide-up delay-300">
        Discover new world with ultra reality and AI-powered development.
        </p>

        <button
  className="mt-6 px-6 py-3 bg-primary text-white rounded-lg hover:bg-secondary transition duration-300 shadow-lg transform hover:scale-105"
  onClick={() => window.location.href = "/contact"}
>
  Contact
</button>



          
        </div>

        <div className="md:w-1/2 flex justify-center relative mt-10">
          <img 
            src={heroImage} 
            alt="Hero" 
            className="w-full rounded-2xl shadow-2xl border-2 border-primary animate-glow mt-5"
          />
        </div>
      </div>

      {/* Integrate Projects Section */}
      <div className="mt-10 w-full">
        <Projects />
      </div>

      {/* Integrate Services Section */}
      <div className="mt-3 w-full">
        <Services />
      </div>

      {/* Integrate About Section */}
      <div className="mt-5 w-full">
        <About />
      </div>

      {/* Integrate Contact Section */}
      <div className="mt-0 w-full">
        <Contact />
      </div>
    </section>
  );
};

export default Home