import { useEffect, useState } from "react";
import image from "../assets/about.png";

const About = () => {
  // State to handle animated numbers
  const [clients, setClients] = useState(0);
  const [services, setServices] = useState(0);
  const [projects, setProjects] = useState(0);

  useEffect(() => {
    const animateNumbers = (setState: React.Dispatch<React.SetStateAction<number>>, target: number) => {
      let count = 0;
      const interval = setInterval(() => {
        count += Math.ceil(target / 50);
        if (count >= target) {
          count = target;
          clearInterval(interval);
        }
        setState(count);
      }, 50);
    };

    animateNumbers(setClients, 500);
    animateNumbers(setServices, 300);
    animateNumbers(setProjects, 200);
  }, []);

  return (
    <section className="bg-gradient-to-r from-black via-gray-900 to-black text-white min-h-screen flex flex-col items-center justify-center">
      {/* About Section */}
      <div className="container mx-auto flex flex-col md:flex-row items-center px-6 mt-10">
        <div className="md:w-1/2 text-center md:text-left">
          <h1 className="text-4xl md:text-6xl font-bold leading-tight mt-10">
            About <span className="text-primary">US</span>
          </h1>
          <p className="mt-4 text-lg text-gray-300">
          Tabsamo Group is a leading tech company specializing in software development, digital marketing, research, business solutions, and skill building. We drive innovation and digital transformation, creating reliable and secure technology to enhance business efficiency and growth. 
          </p>
        </div>
        <div className="md:w-1/2 flex justify-center relative">
          <img 
            src={image} 
            alt="About" 
            className="w-full rounded-2xl shadow-2xl border-2 border-primary animate-glow mt-6"
          />
        </div>
      </div>

      {/* Animated Numbers Section */}
      <section className="bg-gradient-to-r from-black via-gray-900 to-black text-white py-16 w-full">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8">Our Statistics</h2>
          <div className="flex flex-col md:flex-row justify-center items-center gap-12">
            {/* Clients */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-64 text-center">
              <h3 className="text-3xl font-bold text-yellow-400">{clients}+</h3>
              <p className="text-gray-400 mt-2">Happy Clients</p>
            </div>

            {/* Services */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-64 text-center">
              <h3 className="text-3xl font-bold text-yellow-400">{services}+</h3>
              <p className="text-gray-400 mt-2">Services Provided</p>
            </div>

            {/* Projects */}
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-64 text-center">
              <h3 className="text-3xl font-bold text-yellow-400">{projects}+</h3>
              <p className="text-gray-400 mt-2">Completed Projects</p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default About;
