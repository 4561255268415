import project1 from "../assets/project1.jpg";
import project2 from "../assets/project2.jpg";
import project3 from "../assets/project3.jpg";
import project4 from "../assets/project4.jpg";
import project5 from "../assets/project5.jpg";

const projects = [
  { image: project1, title: "AI Chatbot System" },
  { image: project2, title: "Smart Home Automation" },
  { image: project3, title: "E-Learning Platform" },
  { image: project4, title: "Data Service App" },
  { image: project5, title: "VR Game Development" },
];

const Projects = () => {
 

  return (
    <section className="bg-gradient-to-r from-black via-gray-900 to-black text-white py-16 px-6 mt-10">
      <div className="text-center">
        <h2 className="text-4xl font-bold text-blue-500 uppercase tracking-wider">Latest Projects</h2>
        <p className="text-gray-300 mt-2 text-lg">
          AI Development Tools Crafted with Brilliance, Style, Quality and Creativity.
        </p>
      </div>

      {/* Projects Gallery */}
      <div className="mt-10 flex gap-6 overflow-x-scroll scrollbar-hide px-6">
        {projects.map((proj, index) => (
          <div
            key={index}
            className="relative min-w-[250px] md:min-w-[300px] lg:min-w-[350px] h-80 rounded-lg overflow-hidden transform transition-all duration-500"
          >
            <img src={proj.image} alt={proj.title} className="w-full h-full object-cover" />
            <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-60 text-white text-center py-3">
              <h3 className="text-lg font-semibold">{proj.title}</h3>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      
    </section>
  );
};

export default Projects;