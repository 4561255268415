import { useState } from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaGithub, FaLinkedin } from "react-icons/fa";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validation: Ensure all fields are filled
    if (!formData.name || !formData.email || !formData.message) {
      setResponseMessage("Please fill in all fields.");
      return;
    }

    setLoading(true);
    setResponseMessage("");

    try {
      const res = await fetch("https://formspree.io/f/xnnpqeye", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (res.ok) {
        setResponseMessage("Message sent successfully!");
        setFormData({ name: "", email: "", message: "" }); // Clear form
      } else {
        setResponseMessage("Failed to send message. Try again.");
      }
    } catch (error) {
      setResponseMessage("Error sending message.");
    }

    setLoading(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-black via-gray-900 to-black flex items-center justify-center p-6">
      <div className="max-w-4xl w-full bg-white p-8 shadow-lg rounded-2xl">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Contact Us</h2>

        <div className="grid md:grid-cols-2 gap-8">
          {/* Contact Info */}
          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              <FaPhone className="text-primary text-2xl" />
              <a href="tel:616991156" className="text-gray-700 text-lg hover:underline">616991156
              </a> /
              <a href="tel:626991156" className="text-gray-700 text-lg hover:underline">626991156
              </a>

            </div>
            <div className="flex items-center space-x-4">
              <FaEnvelope className="text-primary text-2xl" />
              <a href="mailto:tabsamoapps@gmail.com" className="text-gray-700 text-lg hover:underline">
              tabsamoapps@gmail.com
              </a>

            </div>
            <div className="flex items-center space-x-4">
              <FaMapMarkerAlt className="text-primary text-2xl" />
              <span className="text-gray-700 text-lg">Mogadishu, Somalia</span>
            </div>
            <div className="flex space-x-4 mt-4">
              <a href="https://www.facebook.com/tabsamo?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-500 text-2xl"><FaFacebook /></a>
              <a href="https://github.com/tabsamo" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800 text-2xl"><FaGithub /></a>
              <a href="https://www.linkedin.com/company/tabsamo" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-700 text-2xl"><FaLinkedin /></a>
            </div>
          </div>

          {/* Contact Form */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700 font-medium">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary text-black"
                placeholder="Your Name"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary text-black"
                placeholder="Your Email"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary text-black"
                rows={4}
                placeholder="Your Message"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-primary text-white py-3 rounded-lg text-lg font-semibold hover:bg-opacity-90 transition duration-300"
              disabled={loading}
            >
              {loading ? "Sending..." : "Send Message"}
            </button>
            {responseMessage && <p className="text-center text-red-500">{responseMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}
